import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Icon = styled.i`
  width: 30px;
  height: 30px;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  &:before {
    content: '';
    box-sizing: border-box;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.color.greyShade1};
    border-top-color: ${props => props.theme.color.greyShade5};
    animation: spinner 0.6s linear infinite;
    margin-top: 4px;
  }

  &.spinner-xs {
    width: 15px;
    height: 15px;
    &:before {
      width: 15px;
      height: 15px;
    }
  }
  &.spinner-sm:before {
    width: 30px;
    height: 30px;
  }
  &.spinner-md {
    width: 60px;
    height: 60px;
    &:before {
      width: 60px;
      height: 60px;
    }
  }
  &.spinner-lg {
    width: 90px;
    height: 90px;
    &:before {
      width: 90px;
      height: 90px;
    }
  }
`

export const Spinner = ({ className, size }) => (
  <Icon
    className={cn(className, {
      spinner: true,
      'spinner-xs': size === 'xs',
      'spinner-sm': size === 'sm',
      'spinner-md': size === 'md',
      'spinner-lg': size === 'lg'
    })}
  />
)
Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg'])
}
export default Spinner
