import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Spinner } from '../js/components/Spinner';
import { Button } from '../js/components/Button';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spinner"
    }}>{`Spinner`}</h1>
    <p><inlineCode parentName="p">{`import { Spinner } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`We got spinners!`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Spinner} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <div>\n    <Spinner size=\"xs\" />\n    <Spinner size=\"sm\" />\n    <Spinner size=\"md\" />\n    <Spinner size=\"lg\" />\n  </div>\n  <div>\n    <Button>\n      Loading <Spinner size=\"xs\" />\n    </Button>\n  </div>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Spinner,
      Button,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <div>
    <Spinner size="xs" mdxType="Spinner" />
    <Spinner size="sm" mdxType="Spinner" />
    <Spinner size="md" mdxType="Spinner" />
    <Spinner size="lg" mdxType="Spinner" />
  </div>
  <div>
    <Button mdxType="Button">
      Loading <Spinner size="xs" mdxType="Spinner" />
    </Button>
  </div>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      